import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneCountryCode } from './phone-country-code-field.models';
import { EnvironmentApiService } from '@uc/utilities';

@Injectable({
	providedIn: 'root',
})
export class PhoneCountryCodeFieldService {
	constructor(
		private _http: HttpClient,
		private _apiSrv: EnvironmentApiService,
	) {}

	getCountryCodes() {
		return this._http.get<PhoneCountryCode[]>(
			this._apiSrv.JSON_URL + 'country-calling-codes.json',
		);
	}
}
