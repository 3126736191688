<header class="pt-6 pb-0 sm:border-b sm:border-gray-200 sm:py-6">
	<div class="container">
		<div
			[ngClass]="{
				'sm:border-b sm:border-gray-200 sm:pb-6': details.description,
			}"
		>
			<div class="flex">
				<!-- SVG -->
				<ng-content></ng-content>
				<h1
					class="mb-2 text-2xl font-bold text-black sm:mb-0"
					[ngClass]="{ 'text-uc-green-700': details.success }"
				>
					{{ details.title }}
				</h1>
			</div>

			<p class="text-base text-gray-500 ml-0.5">
				{{ details.subtitle }}
			</p>
		</div>
		@if (details.description) {
			<div
				class="flex py-6 border-b border-gray-200 sm:items-center sm:pb-0 sm:border-b-0"
			>
				@if (details.success) {
					<uc-round-filled-tick-svg
						customStyles="hidden sm:block sm:!h-5 sm:!w-5"
					/>
				}
				<p class="hidden text-xl font-bold text-gray-900 sm:block">
					{{ details.description }}
				</p>
			</div>
		}
	</div>
</header>
<!-- hidden sm:block -->
