<div
	class="relative w-full h-full overflow-hidden overlay-modal-panel"
	aria-labelledby="modal"
	[class.collapsed]="!isModalVisible"
>
	@if (isModalVisible) {
		<div
			class="cursor-pointer overlay"
			aria-hidden="true"
			[@fadeInOut]
			(click)="closeModalPanel()"
		></div>
	}
	<ng-container class="w-full" #content></ng-container>
</div>
