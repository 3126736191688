import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-terms-privacy-policy',
	standalone: true,
	templateUrl: './terms-privacy-policy.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [RouterModule],
})
export class TermsPrivacyPolicyComponent {}
