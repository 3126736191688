import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { ArrowRightSvgComponent, SearchSvgComponent } from '@uc/shared/svg';
import { CommonModule } from '@angular/common';
import { SearchBarService } from '../search-bar.service';

@Component({
	selector: 'uc-header-search-bar',
	standalone: true,
	templateUrl: './header-search-bar.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SearchSvgComponent, ArrowRightSvgComponent, CommonModule],
})
export class HeaderSearchBarComponent {
	@Input() placeholder = 'Search';
	@Input() searchTerm: string | null = '';
	@Input() buttonStyles = 'bg-white hover:bg-gray-100';
	@Input() searchType = '';
	@Output() search = new EventEmitter<string>();

	constructor(private _searchSrv: SearchBarService) {}

	onSearch(keyword: string) {
		this._searchSrv.onValueChange(keyword, this.searchType); //To be removed when it officially replaces the old version.
		this.search.emit(keyword);
	}
}
