const ucApp = {
	title: 'Scan the QR code below to download our app',
	desc: 'Take your uni search on the go with the Uni Compare app. Compare universities and courses directly from your mobile.',
	img: '/assets/img/download_app_qr_code.png',
	alt: 'QR code to download the UniCompare app',
};

const clearingApp = {
	title: 'Scan the QR code to download our Clearing app',
	desc: `Take your Clearing ${new Date().getFullYear()} search on the go with the Clearing app. Compare available Clearing courses and universities directly from your mobile.`,
	img: '/assets/img/onelinkto_clearing.png',
	alt: "QR code to download UniCompare's clearing app",
};

export { ucApp, clearingApp };
