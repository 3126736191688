@if (showAutoSuggest) {
	<div
		class="fixed top-[134px] left-0 z-[999] mt-3 w-screen h-screen bg-white border md:rounded-md md:top-auto md:absolute md:h-auto md:mt-3 md:py-1 md:left-auto"
		[ngClass]="customStyles"
	>
		<ul
			tabindex="0"
			class="overflow-y-auto overscroll-contain h-[calc(100vh-134px)] md:overflow-y-hidden md:h-auto"
		>
			@for (
				suggestion of suggestions | slice: 0 : maxSuggestions;
				track suggestion;
				let i = $index;
				let last = $last
			) {
				<li
					#autosuggestItem
					tabindex="0"
					class="px-3 pt-2 cursor-pointer md:border-none focus:outline-none"
					[ngClass]="{ 'bg-[#E0F2FE] text-uc-blue-900': focusedIndex === i }"
					(keydown)="handleKeyboardEvent($event)"
					(hover)="focusedIndex = i"
					(mouseover)="focusedIndex = i"
					(mouseout)="focusedIndex = -1"
					(click)="setItem(suggestion.name)"
				>
					<div
						class="flex pb-2 text-base text-gray-900 md:border-0 md:text-sm"
						[ngClass]="{ 'border-b': !last }"
					>
						<div class="flex-1 sm:flex">
							<p class="max-w-[280px] mr-1 truncate first-letter:uppercase">
								{{ suggestion.name }}
							</p>
							<span
								class="text-gray-500"
								[ngClass]="{ 'text-uc-blue-900': focusedIndex === i }"
							>
								<span class="hidden sm:inline">(</span
								>{{ suggestion.courses_count + ' Courses'
								}}<span class="hidden sm:inline">)</span>
							</span>
						</div>
						<uc-arrow-right-v2-svg class="self-center inline sm:hidden" />
					</div>
				</li>
			}
		</ul>
	</div>
}
