import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'uc-error-handler',
	standalone: true,
	templateUrl: './error-handler.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorHandlerComponent implements OnInit {
	route = '';
	private _router = inject(Router);

	ngOnInit(): void {
		this.route = this._router.url?.split('?')[0];
	}
}
