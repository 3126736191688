import {
	Component,
	ComponentRef,
	OnDestroy,
	OnInit,
	Type,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { combineLatestWith, map, startWith, Subject, takeUntil } from 'rxjs';

import { FADE_IN_OUT } from '../animations/fade-in-out.animations';
import { ModalService } from './modal.service';

@Component({
	selector: 'uc-modal',
	standalone: true,
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	animations: [FADE_IN_OUT],
	encapsulation: ViewEncapsulation.None,
	imports: [],
})
export class ModalComponent implements OnInit, OnDestroy {
	@ViewChild('content', { read: ViewContainerRef })
	panelContentRef!: ViewContainerRef;

	isModalVisible!: boolean;

	private _componentRef: ComponentRef<any> | null = null;
	private overlayModalServiceSubject$: Subject<void>;

	constructor(private modalService: ModalService) {
		this.overlayModalServiceSubject$ = new Subject<void>();
	}

	ngOnInit() {
		this.modalService
			.onModalVisibilityChange()
			.pipe(takeUntil(this.overlayModalServiceSubject$))
			.subscribe((visible: boolean) => {
				this.isModalVisible = visible;
			});

		this.modalService
			.onContentChange()
			.pipe(
				takeUntil(this.overlayModalServiceSubject$),
				combineLatestWith(this.modalService.data$.pipe(startWith(null))),
				map(([component, data]) => {
					if (component) {
						this.setPanelContent(component, data);
					} else {
						this.panelContentRef.detach();
						this.panelContentRef.clear();
						this._componentRef?.destroy();
						this._componentRef = null;
					}
				}),
			)
			.subscribe();
	}

	closeModalPanel() {
		this.modalService.close();
	}

	private setPanelContent(component: Type<any>, data: any) {
		const classToApply = this.modalService.getStyleClass();
		this.panelContentRef.clear();

		const componentRef = this.panelContentRef.createComponent(component);
		componentRef.instance.data = data;

		const element = componentRef.location.nativeElement.firstChild;
		element.classList.add(classToApply);
		componentRef.changeDetectorRef.markForCheck();
		this._componentRef = componentRef;
	}

	ngOnDestroy() {
		this.modalService.close();
		this.overlayModalServiceSubject$.next();
		this.overlayModalServiceSubject$.complete();
	}
}
