import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PhoneCountryCodeFieldComponent } from '../../form-elements/phone-country-code-field/phone-country-code-field.component';
import { Subscription } from 'rxjs';
import { FormErrorComponent } from '../../form-elements/form-error/form-error.component';
import { InputFieldComponent } from '../input-field/input-field.component';

@Component({
	selector: 'uc-phone-field',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		PhoneCountryCodeFieldComponent,
		FormErrorComponent,
		InputFieldComponent,
		PhoneCountryCodeFieldComponent,
		InputFieldComponent,
	],
	templateUrl: './phone-field.component.html',
})
export class PhoneFieldComponent implements OnInit, OnDestroy {
	@Input() parentForm!: FormGroup;
	@Input() formSubmitted!: boolean;
	@Input() placeholder = 'Mobile Number*';

	showDarkBorder = false;
	private _formFieldSub!: Subscription | undefined;

	get phone() {
		return this.parentForm.get('phoneDetailsForm.phone') as FormControl;
	}

	get countryCode() {
		return this.parentForm.get('phoneDetailsForm.countryCode') as FormControl;
	}

	ngOnInit() {
		this._formFieldSub = this.parentForm
			.get('phoneDetailsForm.phone')
			?.valueChanges.subscribe((value) => {
				this.showDarkBorder = !!value;
			});
	}

	ngOnDestroy() {
		this._formFieldSub?.unsubscribe();
	}
}
