<div
	#tooltip
	class="tooltip-directive"
	[style.left]="left + 'px'"
	[style.top]="top + 'px'"
	[@fadeInOut]
>
	<div class="tooltip-content">
		<div
			class="tooltip-header"
			[ngClass]="{ '!mb-0 !text-center': !description.length }"
		>
			{{ title }}
		</div>
		<div class="tooltip-body">{{ description }}</div>
	</div>
</div>
