import { ProductEnum } from '@uc/web/shared/data-models';
import { CourseSuggestion } from '../models/course-search-autosuggest.models';

export const popularCourses: CourseSuggestion[] = [
	{ name: 'Accounting', courses_count: 529 },
	{ name: 'Business', courses_count: 4808 },
	{ name: 'Computer Science', courses_count: 1277 },
	{ name: 'Criminology', courses_count: 1374 },
	{ name: 'Economics', courses_count: 1770 },
	{ name: 'Education', courses_count: 2078 },
	{ name: 'Engineering', courses_count: 5136 },
	{ name: 'Finance', courses_count: 1430 },
	{ name: 'History', courses_count: 2949 },
	{ name: 'Law', courses_count: 1892 },
	{ name: 'Marketing', courses_count: 1273 },
	{ name: 'Medicine', courses_count: 293 },
	{ name: 'Nursing', courses_count: 1136 },
	{ name: 'Pharmacy', courses_count: 45 },
	{ name: 'Physiotherapy', courses_count: 95 },
	{ name: 'Psychology', courses_count: 1976 },
];

export const pillProperties = [
	{ name: ProductEnum.Undergraduate },
	{ name: ProductEnum.Postgraduate },
];
