import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { RegexService } from '@uc/utilities';

@Component({
	selector: 'uc-password-strength',
	standalone: true,
	templateUrl: './password-strength.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class PasswordStrengthComponent implements OnChanges {
	@Input() password!: string;

	passStrength: string | undefined;
	passLength!: number;
	upperLowercase!: boolean;

	constructor(private regex: RegexService) {}

	ngOnChanges(): void {
		this.passStrength = this.validatePasswordStrength(this.password);
		this.passLength = this.password?.length;
		this.upperLowercase = /[a-z]/.test(this.password) && /[A-Z]/.test(this.password);
	}

	validatePasswordStrength(password: string) {
		if (!password) return;

		if (this.regex.strongPassword.test(password)) {
			return 'strong';
		} else if (this.regex.mediumPassword.test(password)) {
			return 'medium';
		} else {
			return 'low';
		}
	}
}
