import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
@Component({
	selector: 'uc-tooltip',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './tooltip.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(300, style({ opacity: 1 })),
			]),
			transition(':leave', [animate(300, style({ opacity: 0 }))]),
		]),
	],
})
export class TooltipComponent {
	@Input() title = '';
	@Input() description = '';
	@Input() left = 0;
	@Input() top = 0;
}
