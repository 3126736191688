<div class="container my-6">
	<div class="flex justify-center border rounded-lg">
		<p class="px-8 py-12 text-gray-600">
			An unexpected error occurred.&nbsp;<a
				class="blue-link-underline"
				[href]="route"
				>Please try again</a
			>.
		</p>
		<!-- Inject dynamic content -->
		<ng-content></ng-content>
	</div>
</div>
