<section
	aria-hidden="true"
	aria-label="User not signed in"
	class="relative mx-auto bg-white p-6 max-w-[512px] rounded-lg"
>
	<uc-close-modal></uc-close-modal>
	<div class="flex justify-between">
		<uc-download-app-modal-flame-svg></uc-download-app-modal-flame-svg>
	</div>

	<div class="mt-5">
		<h3 class="mb-2 text-lg font-semibold text-gray-900">
			{{ content.title }}
		</h3>
		<p class="mb-5 text-sm text-gray-500">
			{{ content.desc }}
		</p>
		<div class="flex justify-center p-4 bg-gray-50 rounded-xl">
			<img
				[ngSrc]="content.img | cdn"
				[attr.alt]="content.alt"
				width="128"
				height="128"
			/>
		</div>
	</div>
</section>
