import { Component, Input } from '@angular/core';
import {
	ControlContainer,
	ControlValueAccessor,
	FormControl,
	FormGroup,
} from '@angular/forms';

@Component({
	standalone: true,
	template: '',
})
export class BaseControlComponent implements ControlValueAccessor {
	@Input({ required: true }) controlName!: string;
	@Input({ required: true }) formSubmitted!: boolean;
	parent!: ControlContainer;

	value!: any;
	onChange!: any;
	onTouched!: any;
	disabled = false;

	constructor(container: ControlContainer) {
		this.parent = container;
	}

	// // getters
	get form(): FormGroup {
		return this.parent.control as FormGroup;
	}

	get control(): FormControl {
		return this.form.get(this.controlName) as FormControl;
	}

	// control value accessor functions start
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	writeValue(value: any): void {
		this.value = value;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
