import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SadFaceSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'uc-no-result',
	standalone: true,
	templateUrl: './no-result.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [SadFaceSvgComponent],
})
export class NoResultComponent {
	@Input() message = "It looks like your search didn't bring back any results";
}
