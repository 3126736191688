import { Component, forwardRef, HostBinding, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '../base-control/base-control.component';
import { CommonModule } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';

@Component({
	selector: 'uc-input-field',
	standalone: true,
	templateUrl: './input-field.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputFieldComponent),
			multi: true,
		},
	],
	imports: [FormErrorComponent, CommonModule],
})
export class InputFieldComponent extends BaseControlComponent {
	@Input() inputType!: string;
	@Input() dynamicType!: string;
	@Input() placeholder = '';
	@Input() autocomplete!: string;
	@Input() labelText = '';
	@Input() onFocus = '';
	@Input() inputMode = '';
	@Input() customStyles = '';

	constructor(@Optional() protected controlContainer: ControlContainer) {
		super(controlContainer);
	}

	updateValue(value: any): void {
		this.value = value;
		this.onChange(this.value);
	}

	@HostBinding('attr.placeholder')
	focus(): void {
		if (this.placeholder === 'Date of Birth*') {
			this.placeholder = 'DD/MM/YYYY';
		}
	}

	@HostBinding('attr.placeholder')
	focusOut(): void {
		if (this.placeholder === 'DD/MM/YYYY') {
			this.placeholder = 'Date of Birth*';
		}
	}
}
