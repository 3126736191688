<div class="inline-flex items-center">
	<label class="cursor-pointer">
		<input
			#input
			type="checkbox"
			[checked]="value"
			[attr.id]="controlName"
			[attr.name]="controlName"
			(change)="updateValue($event)"
			class="sr-only peer"
			[ngClass]="{
				'outline outline-2 outline-red-500 outline-offset-[-2px]':
					formSubmitted && control.hasError('required'),
			}"
		/>
		<div
			class="relative flex-shrink-0 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-900"
		></div>
	</label>
	<div class="ml-3 text-sm text-gray-500">
		{{ labelText }}
		<!-- for e.g. privacy policy link -->
		<ng-content></ng-content>
	</div>
</div>

<uc-form-error
	fieldError="Please tick the box to confirm your consent"
	[formError]="formSubmitted && control.hasError('required')"
>
</uc-form-error>
