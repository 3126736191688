import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	QueryList,
	ViewChildren,
} from '@angular/core';
import { CourseSuggestion } from '../../models/course-search-autosuggest.models';
import { CommonModule } from '@angular/common';
import { ArrowRightV2SvgComponent } from '@uc/shared/svg';
@Component({
	selector: 'uc-course-suggestions',
	templateUrl: './course-suggestions.component.html',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, ArrowRightV2SvgComponent],
})
export class CourseSuggestionsComponent implements OnChanges {
	@Output() selectSuggestion: EventEmitter<string> = new EventEmitter();
	@Output() resetFocusedIndex: EventEmitter<boolean> = new EventEmitter();
	@Input({ required: true }) parentInput!: HTMLInputElement;
	@Input({ required: true }) suggestions!: CourseSuggestion[];
	@Input({ required: true }) showAutoSuggest!: boolean;
	@Input({ required: true }) focusedIndex!: number;
	@Input({ required: true }) maxSuggestions!: number;
	@Input() customStyles!: string;

	@ViewChildren('autosuggestItem') autosuggestItems!: QueryList<
		ElementRef<HTMLInputElement>
	>;

	ngOnChanges() {
		if (this.focusedIndex === 0) {
			this.setFocusOnItem(this.focusedIndex);
		}
	}

	// Handles keyboard events for the autosuggest dropdown.
	handleKeyboardEvent(event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowDown':
				event.preventDefault();
				this.focusNextItem();
				break;
			case 'ArrowUp':
				event.preventDefault();
				this.focusPreviousItem();
				break;
			case 'Enter':
				if (this.focusedIndex >= 0 && this.focusedIndex < 10) {
					this.setItem(this.suggestions[this.focusedIndex].name);
				}
				break;
		}
	}

	// Moves focus to next in list.
	focusNextItem() {
		this.focusedIndex = Math.min(this.focusedIndex + 1, this.maxSuggestions);
		if (
			this.focusedIndex === this.suggestions.length ||
			this.focusedIndex === this.maxSuggestions
		) {
			this.focusedIndex = 0;
			this.setFocusOnItem(this.focusedIndex);
		}
		this.setFocusOnItem(this.focusedIndex);
		this.scrollOptionIntoView();
	}

	// Moves focus to previous in List or to the parent component.
	focusPreviousItem() {
		this.focusedIndex = Math.max(this.focusedIndex - 1, -1);
		if (this.focusedIndex === -1) {
			this.parentInput.focus();
			this.resetFocusedIndex.emit(true);
		} else {
			this.setFocusOnItem(this.focusedIndex);
			this.scrollOptionIntoView();
		}
	}

	// Sets focus on the item in the list.
	setFocusOnItem(index: number) {
		const itemsArray = this.autosuggestItems.toArray();
		if (itemsArray[index + 1]) {
			const listItem = itemsArray[index + 1].nativeElement;
			listItem.focus();
		}
	}

	// Sets the suggestion chosen by the user and emits it to the parent input
	// or searches for the term if the user is on mobile view.
	setItem(suggested: string) {
		this.selectSuggestion.emit(suggested);
		this.resetFocusedIndex.emit(true);
	}

	//when user navigates with keyboard, the options in dropdown become visible if the dropdown
	// overflows the page.
	scrollOptionIntoView(): void {
		const options = this.autosuggestItems.filter((ele, i) => this.focusedIndex === i);
		if (options[0]) {
			options[0].nativeElement.scrollIntoView({
				block: 'nearest',
				behavior: 'smooth',
			});
		}
	}
}
