import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DescriptionComponent } from './description/description.component';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'uc-heading',
	standalone: true,
	templateUrl: './heading.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [DescriptionComponent, CommonModule],
})
export class HeadingComponent implements OnInit {
	@Input() headingTitle!: string | undefined;
	@Input() description: string | undefined;
	@Input() charLimit = 590;
	@Input() truncateMobileOnly = false;

	shortLength = 590;

	ngOnInit() {
		this.shortLength = this.charLimit;
	}
}
