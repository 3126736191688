import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params, RouterModule } from '@angular/router';

@Component({
	selector: 'uc-pill',
	standalone: true,
	templateUrl: './pill.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, RouterModule],
})
export class PillComponent {
	@Input({ required: true }) label!: string;
	@Input() count: number | undefined;
	@Input() queryParams!: Params;
}
