<div
	class="relative px-3 py-2 border rounded-md shadow-sm focus-within:ring-1 focus-within:ring-gray-400 focus-within:border-gray-400"
	[ngClass]="{
		'border-red-500': formSubmitted && control.hasError('required'),
		'border-gray-200': !formSubmitted || !control.hasError('required'),
	}"
>
	<label
		[for]="idAttr"
		class="absolute top-0 z-50 inline-block px-1 -mt-px text-xs font-medium text-gray-700 bg-white left-2"
		[ngClass]="{ 'show-label': value, label: !value }"
	>
		{{ labelName }}
	</label>

	<div class="relative">
		<input
			(keyup)="updateValue(pass.value)"
			[type]="inputType"
			[name]="idAttr"
			[id]="idAttr"
			[autocomplete]="autoComplete"
			[value]="value"
			[disabled]="disabled"
			[placeholder]="placeholder"
			#pass
			class="block w-full p-0 text-gray-900 border-0 outline-none focus:ring-0"
		/>

		<!-- password visibility toggle -->
		<button
			(click)="togglePassword()"
			type="button"
			class="absolute top-0 bottom-0 right-0 flex flex-col justify-center w-6 h-full cursor-pointer"
		>
			@if (!hidePassword) {
				<uc-unhide-password-svg></uc-unhide-password-svg>
			}
			@if (hidePassword) {
				<uc-hide-password-svg></uc-hide-password-svg>
			}
		</button>
	</div>
</div>

@if (formSubmitted) {
	<!-- missing password -->
	<uc-form-error
		fieldError="Please provide a password to continue."
		[formError]="formSubmitted && control.hasError('required')"
	>
	</uc-form-error>
	<!-- invalid length (e.g. password should be 8 chars long) -->
	<uc-form-error
		fieldError="Passwords must be at least 8 characters long."
		[formError]="
			formSubmitted &&
			control.hasError('minlength') &&
			!control.hasError('required')
		"
	>
	</uc-form-error>
	<!-- invalid password -->
	<uc-form-error
		[fieldError]="control.errors?.['errorMessage'] || 'Invalid password'"
		[formError]="
			formSubmitted && control.hasError('invalid') && !control.hasError('required')
		"
	>
	</uc-form-error>
}
