import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class LoadingAnimationService {
	private paraFull = '<div class="h-4 bg-gray-400 rounded"></div>';
	private paraLong = '<div class="h-4 bg-gray-400 rounded w-5/6"></div>';
	private paraMedium = '<div class="h-4 bg-gray-400 rounded w-3/4"></div>';
	private title = '<div class="h-4 bg-gray-400 rounded w-2/4"></div>';

	private imgEle = '<div class="h-48 bg-gray-400 rounded w-full my-4"></div>';
	private imgEleNoHeight = '<div class="bg-gray-400 rounded h-full w-full"></div>';

	private tableEle = `<div class="my-12"><div class="h-4 bg-gray-400 rounded w-2/5"></div>
  <div class="grid grid-cols-2 gap-2 my-4 w-full justify-between">
  <div class="h-4 bg-gray-400 rounded w-2/12"></div><div class="h-4 bg-gray-400 rounded w-3/12 justify-self-end"></div>
  <div class="h-4 bg-gray-400 rounded w-4/12"></div><div class="h-4 bg-gray-400 rounded w-2/12 justify-self-end"></div>
  <div class="h-4 bg-gray-400 rounded w-3/12"></div><div class="h-4 bg-gray-400 rounded w-4/12 justify-self-end"></div></div></div>`;

	createGenericAnimation(array: string[], border: boolean): string {
		let html = '';

		if (border) {
			html += '<div class="border w-full rounded-md p-4 animate-pulse space-y-8">';
		} else {
			html += '<div class="h-full w-full animate-pulse space-y-8">';
		}

		array.forEach((element) => {
			switch (element) {
				case 'h2':
					html += '<div class="my-4">' + this.title + '</div>';
					break;
				case 'p2':
					html +=
						this.paraMedium +
						'<div class="space-y-2">' +
						this.paraFull +
						'</div>';
					break;
				case 'p3':
					html +=
						this.paraMedium +
						'<div class="space-y-2">' +
						this.paraFull +
						this.paraLong +
						'</div>';
					break;
				case 'img':
					html += this.imgEle;
					break;
				case 'imgNoHeight':
					html += this.imgEleNoHeight;
					break;
				case 'table':
					html += this.tableEle;
					break;
				case 'line':
					html += this.paraFull;
					break;
				default:
					html += '';
			}
		});

		html += '</div>';
		return html;
	}

	//num = number of total paragraphs, lines = number of lines per paragraph
	createPara(num: number, lines: number): string {
		const paraStart =
			'<div class="border w-full rounded-md p-4"><div class="animate-pulse flex space-x-4"><div class="flex-1 space-y-6 py-1">';
		const para2Lines =
			paraStart +
			this.paraMedium +
			'<div class="space-y-2">' +
			this.paraFull +
			'</div></div></div></div>';
		const para3Lines =
			paraStart +
			this.paraMedium +
			'<div class="space-y-2">' +
			this.paraLong +
			this.paraFull +
			'</div></div></div></div>';

		let result = '';

		switch (lines) {
			case 2:
				result += para2Lines;
				break;
			case 3:
				result += para3Lines;
				break;
			default:
				result = para3Lines;
		}

		if (num === 1) {
			return result;
		} else {
			let buildPara = result;
			for (let i = 1; i < num; i++) {
				buildPara += result;
			}
			return buildPara;
		}
	}

	createParaWithDynamicWidth(width: string): string {
		let p = '<div class="border-b">';
		p += '<div class="rounded-md p-6 w-3/4">';
		p += '<div class="animate-pulse flex space-x-4">';
		p += '<div class="flex-1 space-y-2 py-1">';
		p += '<div class="h-4 bg-gray-300 rounded-lg w-3/4"></div>';
		p +=
			'<div class="space-y-2"><div class="h-4 bg-gray-300 rounded-lg ' +
			width +
			'"></div>';
		p += '</div></div></div></div></div>';

		return p;
	}

	createUniPara(num: number): string {
		const widths = ['w-5/6', 'w-3/6'];

		let result = '';

		for (let i = 0; i < num; i++) {
			widths.forEach((w) => {
				result += this.createParaWithDynamicWidth(w);
			});
		}

		return result;
	}

	createUniCard(): string {
		let result =
			'<div class="rounded-md w-full mx-auto"><div class="animate-pulse flex"><div class="flex-1 space-y-12 py-1">';
		result +=
			'<div><div class="h-4 bg-gray-400 rounded w-3/6"></div><div class="h-4 bg-gray-400 rounded w-1/4 my-3"></div>';
		result +=
			'<div class="h-48 bg-gray-400 rounded w-full mt-8 mb-2"></div></div><hr />';
		result +=
			this.tableEle +
			'<hr /><div class="space-y-6"><div class="h-4 bg-gray-400 rounded w-2/4"></div>';
		result += '<div class="h-32 bg-gray-400 rounded w-full"></div></div></div>';

		return result;
	}
}
