@if (showMobileOverlay) {
	<div
		class="fixed top-0 left-0 z-[104] w-screen h-screen bg-white border md:top-auto md:hidden"
	>
		<div class="container">
			<div class="flex items-center justify-between h-18">
				<div class="relative w-full">
					<div
						class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
					>
						<uc-search-svg customStyles="text-gray-400" />
					</div>
					<div class="flex w-full">
						<input
							#mobileInput
							class="w-full px-4 py-2 pl-12 text-base text-gray-600 placeholder-gray-400 border border-gray-300 rounded-lg filter-overlay-search focus:outline-none"
							type="text"
							placeholder="Search Courses"
							aria-label="Search"
							autocomplete="off"
							(keyup.enter)="onSearch(mobileInput.value)"
						/>
					</div>
				</div>
				<div class="flex items-center ml-4 lg:hidden">
					<button
						(click)="closeAutoSuggest(); navigateToPage(parentInput.value)"
						class="h-[42px] inline-flex items-center justify-center p-2.5 text-gray-400 bg-gray-200 rounded-md hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-uc-blue-900"
					>
						<uc-close-modal-svg customStyles="stroke-gray-900" />
					</button>
				</div>
			</div>
		</div>
		<div class="p-4 border-t">
			@for (pill of pills; track pill) {
				<button
					(click)="selectProductOnMobile(pill.name)"
					class="inline-flex items-center justify-center w-auto p-2 mr-4 text-gray-900 border rounded-full cursor-pointer focus:outline-none"
					[ngClass]="{ 'bg-uc-blue-900 text-white': product() === pill.name }"
				>
					{{ pill.name[0].toUpperCase() + pill.name.slice(1) }}
				</button>
			}
		</div>
	</div>
}
@if (suggestions$ | async; as suggestions) {
	<uc-course-suggestions
		[suggestions]="suggestions"
		[parentInput]="parentInput"
		[showAutoSuggest]="showAutoSuggest"
		[focusedIndex]="focusedIndex"
		[customStyles]="customStyles"
		[maxSuggestions]="maxSuggestions"
		(selectSuggestion)="onSelectSuggestion($event)"
		(resetFocusedIndex)="focusedIndex = -1"
	/>
}
