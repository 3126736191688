<div
	class="flex items-center justify-between w-full p-4 text-gray-500 bg-white rounded-lg shadow-lg z-[2000]"
>
	<div class="flex items-center justify-start gap-4">
		@if (message !== 'error') {
			<uc-ringed-tick-svg
				customStyles="flex-shrink-0 w-6 h-6 text-green-500"
			></uc-ringed-tick-svg>
		}
		@if (message === 'error') {
			<uc-exclamation-mark-triangular-svg
				customStyles="text-uc-red-500"
			></uc-exclamation-mark-triangular-svg>
		}
		<div>
			@if (title) {
				<div
					[class]="options.titleClass"
					[attr.aria-label]="title"
					class="text-sm font-medium text-gray-500 custom-title"
				>
					{{ title }}
				</div>
			}
		</div>
	</div>
	@if (options.closeButton) {
		<span class="sr-only">Close</span>
	}
	<uc-close-modal-svg customStyles="!w-5 !h-5 !text-gray-400"></uc-close-modal-svg>
</div>

@if (options.progressBar) {
	<div>
		<div
			class="toast-progress !bg-uc-blue-900 h-0.5"
			[style.width]="width + '%'"
		></div>
	</div>
}
