<a
	routerLink="./"
	[queryParams]="{ s: label }"
	class="p-2.5 text-sm font-bold border border-gray-300 rounded-full whitespace-nowrap capitalize mr-2"
>
	{{ label }}
	@if (count) {
		<span>({{ count | number }})</span>
	}
</a>
