import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type ModalWidthType = 'modal-panel' | 'modal-panel-full';
@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private styleClass: ModalWidthType = 'modal-panel';
	private isModalVisible: boolean;
	private closeModalSource: BehaviorSubject<boolean>;
	private contentChangeSource: Subject<Type<any> | null>;
	private data = new BehaviorSubject<any>(null);
	data$ = this.data.asObservable();

	constructor(@Inject(DOCUMENT) private _document: Document) {
		this.isModalVisible = false;
		this.closeModalSource = new BehaviorSubject<boolean>(this.isModalVisible);
		this.contentChangeSource = new Subject<any>();
	}

	onModalVisibilityChange(): Observable<boolean> {
		return this.closeModalSource.asObservable();
	}

	onContentChange(): Observable<Type<any> | null> {
		return this.contentChangeSource.asObservable();
	}

	setContent(content: Type<any>) {
		this.contentChangeSource.next(content);
	}

	setData(data: any) {
		this.data.next(data);
	}

	getStyleClass() {
		return this.styleClass;
	}

	setStyleClass(type: ModalWidthType) {
		this.styleClass =
			type === 'modal-panel-full' ? 'modal-panel-full' : 'modal-panel';
	}

	show() {
		this.toggleClass('add');
		this.isModalVisible = true;
		this.closeModalSource.next(this.isModalVisible);
	}

	close() {
		this.setStyleClass('modal-panel');
		this.toggleClass('remove');
		this.isModalVisible = false;
		this.closeModalSource.next(this.isModalVisible);
		this.contentChangeSource.next(null as unknown as Type<any>);
	}

	toggleClass(toggle: string) {
		const elem = this._document.querySelector('body');
		if (toggle === 'add') return elem?.classList.add('overflow-hidden');
		elem?.classList.remove('overflow-hidden');
	}
}
