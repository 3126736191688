import {
	Component,
	ElementRef,
	HostListener,
	EventEmitter,
	Output,
	ViewChild,
	Input,
	OnInit,
	ChangeDetectorRef,
	OnDestroy,
	Inject,
	Renderer2,
	DestroyRef,
} from '@angular/core';
import {
	Observable,
	Subscription,
	debounceTime,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	of,
	startWith,
	switchMap,
	tap,
} from 'rxjs';
import { CourseSearchAutosuggestApiService } from '../data-access/course-search-autosuggest-api.service';
import { CourseSuggestion, mockEvent } from '../models/course-search-autosuggest.models';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
	ArrowRightV2SvgComponent,
	SearchSvgComponent,
	CloseModalSvgComponent,
} from '@uc/shared/svg';
import { ActivatedRoute, Router } from '@angular/router';
import {
	popularCourses,
	pillProperties,
} from '../utils/course-search-autosuggest.properties';
import { CourseSuggestionsComponent } from '../components/course-suggestions/course-suggestions.component';
import { Product, ProductEnum, ScreenWidth } from '@uc/web/shared/data-models';
import { CourseSearchAutosuggestService } from '../utils/course-search-autosuggest.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'uc-course-search-autosuggest',
	templateUrl: './course-search-autosuggest.component.html',
	standalone: true,
	imports: [
		CommonModule,
		ArrowRightV2SvgComponent,
		SearchSvgComponent,
		CourseSuggestionsComponent,
		CloseModalSvgComponent,
	],
	providers: [CourseSearchAutosuggestApiService],
})
export class CourseSearchAutosuggestComponent implements OnInit, OnDestroy {
	@Output() selectSuggestion: EventEmitter<string> = new EventEmitter();
	@Output() autoSuggestOpen: EventEmitter<boolean> = new EventEmitter();
	@Input({ required: true }) parentInput!: HTMLInputElement;
	@Input() customStyles!: string;
	@Input() showAutoSuggest!: boolean;
	product = this._autosuggestSrv.product;
	showMobileOverlay!: boolean;
	focusedIndex = -1;
	pills = pillProperties;
	maxSuggestions = 10;
	suggestions$: Observable<CourseSuggestion[] | null> = of(popularCourses);

	private _isMobile!: boolean;
	private _downArrowKeyPress!: Subscription;
	private _inputFocusSub!: Subscription;
	private _onEnterSub!: Subscription;
	private _breakpointSub = this._breakpointObserver
		.observe(`(max-width: ${ScreenWidth.SM - 1}px)`)
		.subscribe((result) => (this._isMobile = result.matches));

	@ViewChild('mobileInput') mobileInput!: ElementRef;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private _elementRef: ElementRef,
		private _cdRef: ChangeDetectorRef,
		private _router: Router,
		private _route: ActivatedRoute,
		private _renderer: Renderer2,
		private _destroyRef: DestroyRef,
		private _breakpointObserver: BreakpointObserver,
		private _autosuggestApiSrv: CourseSearchAutosuggestApiService,
		private _autosuggestSrv: CourseSearchAutosuggestService,
	) {}

	ngOnInit() {
		if (this._isMobile) {
			this._inputFocusSub = fromEvent<FocusEvent>(this.parentInput, 'focus')
				.pipe(
					tap((event: FocusEvent) => {
						if (event.type === 'focus' && this._isMobile) {
							this.showMobileOverlay = true;
							this._renderer.addClass(
								this._document.body,
								'overflow-hidden',
							);
							this._cdRef.detectChanges();
							this.parentInput = this.mobileInput.nativeElement;
							this.parentInput.focus();
						}
						this.suggestions$ = this._onInputChange(this.parentInput);
						this._downArrowKeyPress = this._onInputDownArrowKeyPress(
							this.parentInput,
						);
					}),
				)
				.subscribe();
		} else {
			this.suggestions$ = this._onInputChange(this.parentInput);
			this._downArrowKeyPress = this._onInputDownArrowKeyPress(this.parentInput);
			this._onEnterSub = this._onEnter(this.parentInput);
		}

		this._setProduct(this._route.snapshot.params['product']);
	}

	ngOnDestroy() {
		this._downArrowKeyPress?.unsubscribe();
		this._inputFocusSub?.unsubscribe();
		this._onEnterSub?.unsubscribe();
		this._breakpointSub?.unsubscribe();

		this._renderer.removeClass(this._document.body, 'overflow-hidden');
	}

	// Handles the input change event and returns the suggestions from the api
	private _onInputChange(eventTarget: HTMLInputElement) {
		return fromEvent(eventTarget, 'input').pipe(
			takeUntilDestroyed(this._destroyRef),
			startWith({ target: { value: '' } }),
			debounceTime(300),
			distinctUntilChanged(),
			map((elem: mockEvent) => (elem.target as HTMLInputElement).value),
			switchMap((term) => {
				if (term.length < 1 && this._isMobile) {
					this.maxSuggestions = 16;
					return of(popularCourses);
				} else if (term.length < 1 && !this._isMobile) {
					this.showAutoSuggest = false;
					this._cdRef.detectChanges();
					return of([]);
				} else {
					this.maxSuggestions = 10;
					return this._autosuggestApiSrv.getCourseSuggestions(
						term,
						this.product(),
					);
				}
			}),
			filter((suggestions: CourseSuggestion[]) => suggestions.length > 0),
			tap(() => {
				this.showAutoSuggest = true;
				this.autoSuggestOpen.emit(true);
			}),
		);
	}

	// Handles the down arrow key press event on the input field to move focus to
	// the autosuggest.
	private _onInputDownArrowKeyPress(eventTarget: HTMLInputElement) {
		return fromEvent<KeyboardEvent>(eventTarget, 'keydown')
			.pipe(
				takeUntilDestroyed(this._destroyRef),
				filter((event: KeyboardEvent) => event.key === 'ArrowDown'),
				tap((event) => {
					if (
						(this.focusedIndex === -1 && this.showAutoSuggest) ||
						(this.focusedIndex === -1 && this.showMobileOverlay)
					) {
						event.preventDefault();
						this.focusedIndex = 0;
						this._cdRef.detectChanges();
					}
				}),
			)
			.subscribe();
	}

	onSelectSuggestion(event: string) {
		this._isMobile ? this.onSearch(event) : this.selectSuggestion.emit(event);
		this.closeAutoSuggest();
	}

	// Used by mobileInput only.
	onSearch(searchTerm: string) {
		this.navigateToPage(searchTerm);
		this._elementRef.nativeElement.value = '';
		this.closeAutoSuggest();
	}

	// Sets the product chosen by the user on mobile view.
	selectProductOnMobile(product: ProductEnum) {
		this._autosuggestSrv.product.set(product);
	}

	navigateToPage(searchTerm: string) {
		if (!searchTerm?.trim()) {
			const queryParams = { ...this._route.snapshot.queryParams };
			delete queryParams['s'];
			this._router.navigate(['/courses', this.product()], {
				queryParams: queryParams,
			});
		} else {
			this._router.navigate(['/courses', this.product()], {
				queryParams: {
					s: searchTerm.toLowerCase().trim(),
				},
				queryParamsHandling: 'merge',
			});
		}
	}

	closeAutoSuggest() {
		this.showMobileOverlay = false;
		this.showAutoSuggest = false;
		this._renderer.removeClass(this._document.body, 'overflow-hidden');
		this.autoSuggestOpen.emit(false);
	}

	// Closes the autosuggest dropdown when user clicks outside of it.
	@HostListener('document:click', ['$event'])
	private _clickout(event: MouseEvent) {
		if (!this._elementRef.nativeElement.contains(event.target)) {
			this.showAutoSuggest = false;
			this.focusedIndex = -1;
			this.autoSuggestOpen.emit(false);
		}
	}

	// Closes the autosuggest dropdown when user presses enter.
	private _onEnter(eventTarget: HTMLInputElement) {
		return fromEvent(eventTarget, 'keyup')
			.pipe(
				takeUntilDestroyed(this._destroyRef),
				tap((e: Event) => {
					if ((e as KeyboardEvent).key === 'Enter') {
						this.showAutoSuggest = false;
						this.focusedIndex = -1;
						this.autoSuggestOpen.emit(false);
					}
				}),
			)
			.subscribe();
	}

	private _setProduct(studyLevel: Product | undefined) {
		const product = studyLevel || ProductEnum.Undergraduate;
		this._autosuggestSrv.product.set(product);
	}
}
