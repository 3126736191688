<div class="px-6 pb-12 border border-gray-200 rounded-lg pt-7" aria-hidden="true">
	<div class="flex items-center justify-center">
		<div class="text-center">
			<uc-sad-face-svg></uc-sad-face-svg>
			<p class="mt-12 text-lg font-bold">
				{{ message }}
			</p>
			<ng-content></ng-content>
		</div>
	</div>
</div>
