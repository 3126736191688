import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-loading-animation',
	standalone: true,
	templateUrl: './loading-animation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingAnimationComponent {
	@Input() htmlString!: string;
}
