@if (policyTitle) {
	<div class="py-10">
		<h3 class="mb-5 text-lg font-semibold text-black">{{ policyTitle }}</h3>
		<p class="text-gray-500">
			{{ text }}
			@if (showUcPrivacyPolicy) {
				our
				<a routerLink="/privacy" class="underline blue-link" target="_blank"
					>Privacy Policy</a
				>
				and
			}
			{{ universityName }}'s
			<a
				[attr.href]="policyLink"
				class="underline blue-link"
				target="_blank"
				rel="noopener"
				>Privacy Policy</a
			>.
			@if (showRecaptchaInfo) {
				<uc-recaptcha-info />
			}
		</p>
	</div>
}
