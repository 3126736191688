import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CourseSuggestion } from '../models/course-search-autosuggest.models';
import { Environment } from '@uc/utilities';
import { Product } from '@uc/web/shared/data-models';

@Injectable({ providedIn: 'root' })
export class CourseSearchAutosuggestApiService {
	api_url!: string;

	constructor(
		@Inject('environment') environment: Environment,
		private http: HttpClient,
	) {
		this.api_url = environment.api_url;
	}

	getCourseSuggestions(value: string, product: Product = 'undergraduate') {
		return this.http.get<CourseSuggestion[]>(
			`${this.api_url}course-suggestions?query=${value}&type=${product}`,
		);
	}
}
