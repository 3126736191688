<!-- truncate text -->
@if (description) {
	<div
		[class.md:hidden]="truncateMobileOnly"
		[class.lg:grid]="!truncateMobileOnly"
		[ngClass]="customPaddingTop"
		class="lg:grid-cols-1 lg:gap-8"
	>
		<p class="text-base text-gray-500">
			<span
				class="mr-1"
				[innerHTML]="
					description.length === charLimit && description.length > shortLength
						? (description | ucEntities)
						: (description | ucEntities | truncate: [charLimit])
				"
			>
			</span>
			@if (description.length > charLimit) {
				<button
					(click)="charLimit = description.length"
					class="blue-link-underline"
				>
					Read more
				</button>
			}
			@if (description.length === charLimit && description.length > shortLength) {
				<button (click)="charLimit = shortLength" class="blue-link-underline">
					Read less
				</button>
			}
		</p>
	</div>
}

<!-- show full text on desktop -->
@if (description) {
	<div
		[class.md:block]="truncateMobileOnly"
		[ngClass]="customPaddingTop"
		class="hidden"
	>
		<p class="text-base text-gray-500">
			{{ description | ucEntities }}
		</p>
	</div>
}
