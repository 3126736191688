import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoWithTextComponent } from '../../logo-with-text/logo-with-text.component';

@Component({
	selector: 'uc-leads-header-v2',
	standalone: true,
	templateUrl: './leads-header-v2.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule, LogoWithTextComponent],
})
export class LeadsHeaderV2Component {
	@Input({ required: true }) headline!: string;
	@Input() displayLogo = true;
	@Input() subtitle = '';
	@Input() customHeadlineStyles = '';
}
