import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Subject } from 'rxjs';

type SearchBar = {
	searchTerm: string;
	searchType: string;
	fetchData: boolean;
	ignoreEmission: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class SearchBarService {
	private inputValue = new BehaviorSubject<string>('');
	inputValue$ = this.inputValue.asObservable().pipe(distinctUntilChanged());

	/**
	 * Part of the refactoring and is used in the universities component
	 * if this can be used in the other places then we can remove the @inputValue
	 */
	private searchValue = new Subject<SearchBar>();
	searchValue$ = this.searchValue.asObservable().pipe(distinctUntilChanged());

	onValueChange(
		value: string,
		searchType = '',
		fetchData = true,
		ignoreEmission = false,
	) {
		const _value = value?.toLowerCase();
		this.inputValue.next(_value);
		this.searchValue.next({
			searchTerm: _value,
			searchType,
			fetchData,
			ignoreEmission,
		});
	}
}
