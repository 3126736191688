import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { CdnPathPipe } from '@uc/utilities';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'uc-logo-with-text',
	standalone: true,
	templateUrl: './logo-with-text.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CdnPathPipe, NgOptimizedImage, RouterModule],
})
export class LogoWithTextComponent {
	@Input() link = '/';
}
