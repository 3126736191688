import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'uc-star-rating',
	standalone: true,
	templateUrl: './star-rating.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class StarRatingComponent {
	@Input() rating = 0;

	getStarRating(index: number, rating: number): string {
		if (!index || !rating) return '0%';

		const i = index;
		const r = rating;

		let result;
		if (r >= i) {
			result = 100;
		} else if (i - r >= 1) {
			result = 0;
		} else {
			result = 100 - parseInt(((i - r) * 100).toFixed(1));
		}
		return result + '%';
	}

	getUniqueSVGid(): string {
		return '_' + Math.random().toString(36).substring(2, 9);
	}
}
