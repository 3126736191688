<div
	[ngClass]="{
		'border-red-500 focus-within:ring-red-600 focus-within:border-red-600':
			formSubmitted && control.errors,
		'border-gray-300 focus-within:ring-gray-400': !formSubmitted && !control.errors,
	}"
	class="relative col-span-1 px-3 py-2 border rounded-md shadow-sm input-focus-within"
>
	<label
		[attr.for]="controlName"
		class="absolute top-0 inline-block px-1 -mt-px text-xs font-medium text-gray-700 bg-white left-2"
		[ngClass]="{ 'show-label': value, label: !value }"
	>
		Date of Birth
	</label>
	<input
		#input
		ucDateFormatter
		[(ngModel)]="output"
		[value]="value"
		[attr.id]="controlName"
		[attr.name]="controlName"
		[attr.placeholder]="placeholder"
		type="text"
		inputmode="numeric"
		autocomplete="bday"
		maxlength="10"
		(input)="updateValue(input.value)"
		(focus)="focus()"
		(focusout)="focusOut()"
		class="block w-full p-0 text-gray-900 border-0 outline-none focus:outline-none focus:ring-0"
	/>
</div>

@if (formSubmitted) {
	<div class="w-full">
		<!-- for required errors -->
		<uc-form-error
			fieldError="Please enter your date of birth."
			[formError]="formSubmitted && control.hasError('required')"
		>
		</uc-form-error>
		<!-- invalid Date of Birth -->
		<uc-form-error
			fieldError="Please enter DD/MM/YYYY format. Users must be 14 years of age or above."
			[formError]="
				formSubmitted &&
				control.hasError('dateInvalid') &&
				!control.hasError('required')
			"
		>
		</uc-form-error>
	</div>
}
