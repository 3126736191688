@if (headingTitle) {
	<h2
		class="secondary-header"
		[innerHTML]="headingTitle"
		[ngClass]="{ '!mb-0': description }"
	></h2>

	<uc-description
		[truncateMobileOnly]="truncateMobileOnly"
		[description]="description"
		[charLimit]="charLimit"
		[shortLength]="shortLength"
		customPaddingTop="pt-2 md:pt-4"
	/>
}
