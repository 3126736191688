<main class="relative lg:min-h-screen">
	<div class="relative flex items-center lg:min-h-screen">
		<!-- background image -->
		<div
			class="absolute top-0 right-0 hidden w-1/2 h-full ml-auto lg:block bg-register"
		></div>

		<div class="container grid lg:grid-cols-2">
			<!-- left panel -->
			<section
				class="py-8 lg:pr-14 xl:pr-40 lg:border-r lg:border-gray-200 sm:py-16"
			>
				<ng-content></ng-content>
			</section>

			<!-- right panel -->
			<ng-content select="[rightPanel]"></ng-content>
		</div>
	</div>
</main>
