<div class="relative">
	<!-- backdrop: when dropdown shows, clicking on it closes it -->
	<div
		(click)="showSelectBox(false)"
		class="inset-0 border cursor-pointer"
		aria-hidden="true"
		[ngClass]="{ 'fixed z-50': displayBox, hidden: !displayBox }"
	></div>

	<!-- custom input field design -->
	<div class="relative" [ngClass]="{ 'z-50': displayBox }">
		<div
			#container
			[ngClass]="{
				'border-red-500 focus-within:ring-red-600 focus-within:border-red-600':
					formSubmitted && control.hasError('required'),
				'border-gray-300 focus-within:!ring-gray-400':
					!formSubmitted && !control.hasError('required'),
			}"
			class="relative flex px-3 py-2 bg-white border rounded-md shadow-sm input-focus-within"
		>
			<!-- label -->
			<span
				class="absolute top-0 inline-block px-1 -mt-px text-xs font-medium text-gray-700 bg-white left-2"
				[ngClass]="{
					'show-label': showLabel || value,
					label: !showLabel && !value,
				}"
				>{{ labelName }}</span
			>
			<div class="flex items-center justify-between w-full cursor-pointer">
				<!-- Display Value -->
				@if (!displayBox) {
					<button
						(click)="showSelectBox(true)"
						type="button"
						class="w-full h-full text-base text-left truncate focus:outline-none"
						[ngClass]="{
							'text-gray-500': !value,
						}"
					>
						@if (isArrayOfObjects === false) {
							<span>
								{{ value ? value : placeholder }}
							</span>
						}
						@if (isArrayOfObjects === true) {
							<span>
								{{ value && value[key] ? value[key] : placeholder }}
							</span>
						}
					</button>
				}

				<!-- Input Field -->
				@if (displayBox) {
					<input
						#searchInput
						(keydown)="onKeyDown($event)"
						(input)="onSearch(searchInput.value)"
						autocomplete="{{ autocomplete ? autocomplete : 'off' }}"
						name="search"
						type="search"
						class="w-full p-0 border-none outline-none focus:ring-0"
					/>
				}

				<!-- arrow icon pointing down: allows to toggle dropdown visibility -->
				<uc-arrow-down-svg
					(click)="showSelectBox()"
					[customStyles]="!value ? 'text-gray-500' : ''"
				/>
			</div>
		</div>
		<!-- Errors -->
		@if (formSubmitted && control.hasError('required')) {
			<div class="w-full" [ngClass]="{ show: displayBox }">
				<uc-form-error
					[fieldError]="errorMsg"
					[formError]="formSubmitted && control.hasError('required')"
				/>
			</div>
		}
	</div>

	<!-- Options -->
	@if (displayBox) {
		<ul
			tabindex="-1"
			class="absolute left-0 w-full py-2 mt-2 overflow-auto bg-white border rounded-lg shadow-lg z-[9999] top-10"
			style="max-height: 400px"
		>
			@for (elem of data; track elem; let i = $index) {
				<div #options class="flex justify-between">
					<li
						(click)="onSelectOption(elem)"
						(mouseout)="focusedValueIndex = null"
						(mouseover)="focusedValueIndex = i"
						[ngClass]="{
							'bg-uc-blue-900 text-white': focusedValueIndex === i,
							'!text-gray-400 !bg-white !cursor-default':
								elem.selected === 1 && value !== elem,
						}"
						class="flex justify-between w-full px-4 py-2 cursor-pointer"
						role="option"
						[attr.aria-label]="i"
					>
						@if (data?.length > 0 && isArrayOfObjects === true) {
							<p>
								{{ elem[key] }}
							</p>
						}
						@if (data?.length > 0 && isArrayOfObjects === false) {
							<p>
								{{ elem }}
							</p>
						}
						@if (disableTick === false && value) {
							<div>
								@if (
									value[key] === elem[key] && isArrayOfObjects === true
								) {
									<uc-tick-svg
										customStyles="{
							'bg-uc-blue-900 text-white': focusedValueIndex === i
						}"
									/>
								}
								@if (value === elem && isArrayOfObjects === false) {
									<uc-tick-svg
										customStyles="{
							'bg-uc-blue-900 text-white': focusedValueIndex === i
						}"
									/>
								}
							</div>
						}
					</li>
				</div>
			}
			@if (data?.length <= 0) {
				<span class="px-4 py-3 text-sm text-gray-500">
					No results match your search.
				</span>
			}
		</ul>
	}
</div>
