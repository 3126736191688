<div class="w-full">
	<label for="search" class="sr-only">Search</label>
	<div class="relative">
		<div
			class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
			[ngClass]="iconPadding"
		>
			<uc-search-svg customStyles="text-gray-400" />
		</div>
		<div class="flex w-full">
			<input
				#searchBox
				class="w-full px-4 pl-16 text-base text-gray-600 placeholder-gray-500 bg-white border !border-gray-300 rounded-lg filter-overlay-search"
				[ngClass]="{
					'sm:rounded-r-none border-r-0 focus-within:ring-0': displayButton,
					'input-focus-within': !displayButton,
				}"
				[class]="customStyles"
				type="search"
				[value]="keyword"
				(input)="onChange($event)"
				(keyup.enter)="
					onSearch(searchBox.value); onSearchCourseMethod('Navbar Search Box')
				"
				id="inputSearchUni"
				[placeholder]="placeholder"
				aria-label="Search"
				autocomplete="off"
			/>
			<!-- search button for desktop -->
			@if (displayButton) {
				<button
					(click)="onSearch(searchBox.value)"
					class="items-center justify-center hidden px-6 py-3 text-xl font-semibold bg-white border-l-2 rounded-r-lg sm:flex border-l-uc-blue-900 text-uc-blue-900 hover:bg-gray-100 w-36"
					[ngClass]="buttonStyles"
				>
					Search
					<uc-arrow-right-svg customStyles="w-4 h-4 sm:w-6 sm:h-6 ml-2.5" />
				</button>
			}
		</div>
	</div>

	<!-- search button for mobile -->
	@if (displayButton) {
		<button
			(click)="onSearch(searchBox.value)"
			class="flex items-center justify-center w-full gap-4 px-8 py-2 mt-4 rounded-md sm:hidden"
			[ngClass]="buttonStyles"
		>
			Search
			<uc-arrow-right-svg customStyles="text-inherit" />
		</button>
	}
</div>
@if (autoSuggestEnabled) {
	<uc-course-search-autosuggest
		[parentInput]="searchBox"
		[showAutoSuggest]="showAutoSuggest"
		(selectSuggestion)="
			onChange($event); parentInput === 'navInput' && onSearch($event)
		"
		(autoSuggestOpen)="onAutoSuggestOpen($event)"
		customStyles="md:w-[404px]"
	/>
}
