<header class="mb-8">
	@if (displayLogo) {
		<uc-logo-with-text></uc-logo-with-text>
	}
	<h1
		[ngClass]="customHeadlineStyles"
		class="mb-3 text-xl font-bold leading-8 text-gray-900"
	>
		{{ headline }}
	</h1>
	<p class="text-base leading-6 text-gray-500 sm:text-base">
		{{ subtitle }}
	</p>
</header>
